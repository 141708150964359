import {
  markRaw, onBeforeUnmount, onMounted, Ref,
} from 'vue';
import { Debtor, useDebtors } from '@/hooks/useDebtors';
import { ProductionType } from '@/hooks/useConstructor';
import { useSocket } from '@/hooks/useSocket';
import { useUnsubs } from '@/hooks/useUnsubs';
import { UpdatedDebtorDataSocketMessage } from '@/types/socket';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useStore } from 'vuex';
import { companiesWithBilling } from '@/store/modules/debtors';
import { getDebtorIcons } from '@/store/modules/debtors/getDebtorIcons';

export const useSubscribeDebtorStatusUpdate = (
  debtors: Ref<Debtor[]>,
  productionType: Ref<ProductionType>,
) => {
  const store = useStore();
  const { companyId, companyModuleType } = useProtectedDefaultCompany();
  const { subscribe } = useSocket();
  const { sub } = useUnsubs();
  const {
    fetchDebtor,
  } = useDebtors();

  onMounted(async () => {
    const unsubSocket = await subscribe<UpdatedDebtorDataSocketMessage>({
      condition: (payload) => payload.data?.event === 'updated_status' && payload.data.production_type === productionType.value,
      handler: (payload) => {
        console.log('updated_status', payload);
        const hasDebtor = debtors.value.find(
          (d) => d.debtor.pk === payload.data.debtor_id,
        );
        if (!hasDebtor) {
          return;
        }
        fetchDebtor({
          productionType: productionType.value,
          companyId: companyId.value,
          module: companyModuleType.value,
          id: payload.data.debtor_id,
        }).then((debtorResponse) => {
          if (!debtorResponse.status) {
            return;
          }
          const statusesMap = store.getters['dicts/dictsMap'].debtorStatuses;
          const substatusesMap = store.getters['dicts/dictsMap'].debtorSubstatuses;
          const debtor = debtorResponse.response;
          const companyHasBilling = store.getters['companies/defaultCompanyHasBilling'] || companiesWithBilling.includes(companyId.value);
          const { icons, meta } = getDebtorIcons(
            debtorResponse.response,
            statusesMap,
            substatusesMap,
            companyModuleType.value,
            productionType.value,
            companyHasBilling,
          );
          debtor.icons = icons;
          debtor.meta = meta;

          const indexToReplace = debtors.value.findIndex(
            (d) => d.debtor.pk === payload.data.debtor_id,
          );

          if (indexToReplace === -1) {
            return;
          }

          debtors.value.splice(indexToReplace, 1, markRaw(debtor));
        });
      },
    });
    sub(unsubSocket);
  });
};
