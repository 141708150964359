import { useEmployees } from '@/hooks/useEmployees';
import { ref } from 'vue';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';

export function useFilterEmployees() {

  const employees = ref<{ value: number; label: string }[]>([]);

  const {
    companyId,
  } = useProtectedDefaultCompany();

  const { fetchEmployees } = useEmployees();

  fetchEmployees({ filters: { companyId: companyId.value }, limit: 1000, page: 1 }).then((e) => {
    if (e.status) {
      employees.value = e.response.results
        .map((u) => ({ label: `${u.first_name} ${u.last_name}`, value: u.id }));
    }
  });

  return employees;
}
