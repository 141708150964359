import { useStore } from 'vuex';
import { SignalType, useSignal } from '@/hooks/useSignal';

export const useFetchWithHidePreloader = <
  Func extends (...args: any[]) => Promise<any>,
  >(prefetch: Func) => {
  const store = useStore();
  const { dispatchSignal } = useSignal();

  return async (...args: Parameters<Func>) => {
    const response = await prefetch(...args);
    dispatchSignal(SignalType.globalPreloaderDataIsLoaded);
    return response;
  };
};
