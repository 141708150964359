import {
  computed, onMounted, Ref, ref,
} from 'vue';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { ProductionType } from '@/hooks/useConstructor';
import { CompanyModuleType, DebtorQuery } from '@/pages/debtors/_module/useDebtorsActions';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useI18n } from 'vue-i18n';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { IsIonicApp } from '@/symbols';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useStatusOptions } from '@/pages/debtors/_module/filters/useStatusOptions';
import { useFilterCourts } from '@/pages/debtors/_module/filters/useFilterCourts';
import { useFilterEmployees } from '@/pages/debtors/_module/filters/useFilterEmployees';
import { useMultifilter } from '@/pages/debtors/_module/filters/useMultifilter';
import { useCompanies } from '@/hooks/useCompanies';
import { useCompanySettings } from '@/components/automation/useCompanySettings';

export function useDebtorFilters(
  module: Ref<ProductionType>,
) {
  const { t } = useLocalI18n('debtors');
  const { t: rootT } = useI18n();
  const isIonicApp = useProtectedInject(IsIonicApp);
  const {
    company,
    companyModuleType,
  } = useProtectedDefaultCompany();
  const { companySettings } = useCompanySettings();
  const { getMultifilter } = useMultifilter(module);
  const { statusOptions } = useStatusOptions(module);
  const {
    awaitRegionalCourts,
    awaitMagistrateCourts,
    bailiffs,
    bailiffsFiltersModel,
  } = useFilterCourts(module);
  const employees = useFilterEmployees();

  const districtOptions = [
    'ЦАО',
    'САО',
    'СВАО',
    'ВАО',
    'ЮВАО',
    'ЮАО',
    'ЮЗАО',
    'ЗАО',
    'СЗАО',
    'ЗелАО',
  ].map((el) => ({ label: el, value: el }));

  return computed<Array<ActiveFormField<DebtorQuery>>>(() => ((([
    ...getMultifilter(),
    {
      key: 'company_id',
      field: 'company_id',
      type: ActiveFormFieldType.input,
      defaultValue: company.value.id,
      isResettable: false,
      isVisible: false,
    },
    {
      key: 'production_type',
      field: 'production_type',
      type: ActiveFormFieldType.input,
      defaultValue: module.value,
      isResettable: false,
      isVisible: false,
    },
    {
      key: 'full_name',
      field: 'full_name',
      type: ActiveFormFieldType.input,
      width: companyModuleType.value === CompanyModuleType.Communal ? 1.6 : 2,
      ionFieldGroup: {
        key: 'full_name',
        label: t('filterGroupLabels.full_name'),
      },
      options: {
        placeholder: t('filters.full_name'),
      },
    },
    companyModuleType.value === CompanyModuleType.Communal && {
      key: 'search_name_deep',
      field: 'search_name_deep',
      type: ActiveFormFieldType.checkbox,
      style: { justifyContent: 'center' },
      width: 0.4,
      ionFieldGroup: {
        key: 'full_name',
        groupOrder: 1,
        label: t('filterGroupLabels.full_name'),
      },
      options: {
        label: t('filters.search_name_deep'),
      },
    },
    {
      key: 'address',
      field: 'address',
      type: ActiveFormFieldType.input,
      width: 2,
      options: {
        placeholder: t('filters.address'),
      },
      ionFieldGroup: {
        key: 'full_name',
        groupOrder: 1,
        label: t('filterGroupLabels.full_name'),
      },
    },
    {
      key: 'phone_number',
      field: 'phone_number',
      type: ActiveFormFieldType.input,
      width: 2,
      options: {
        placeholder: t('filters.phone_number'),
      },
      ionFieldGroup: {
        key: 'full_name',
        groupOrder: 1,
        label: t('filterGroupLabels.full_name'),
      },
    },
    {
      key: 'personal_account',
      field: 'personal_account',
      type: ActiveFormFieldType.input,
      width: 2,
      options: {
        placeholder: t('filters.personal_account'),
        withSplitStringOnPaste: true,
      },
      ionFieldGroup: {
        key: 'full_name',
        groupOrder: 1,
        label: t('filterGroupLabels.full_name'),
      },
    },
    {
      key: 'debtor_debt_min',
      field: 'debtor_debt_min',
      type: ActiveFormFieldType.input,
      ionFieldGroup: {
        key: 'debtor_debt',
        groupOrder: 2,
        label: t('filterGroupLabels.debtor_debt'),
      },
      options: {
        modelModifiers: { number: true },
        placeholder: t('filters.debtor_debt_min'),
      },
    },
    {
      key: 'debtor_debt_max',
      field: 'debtor_debt_max',
      type: ActiveFormFieldType.input,
      ionFieldGroup: {
        key: 'debtor_debt',
        groupOrder: 2,
        label: t('filterGroupLabels.debtor_debt'),
      },
      options: {
        modelModifiers: { number: true },
        placeholder: t('filters.debtor_debt_max'),
      },
    },
    module.value === ProductionType.executive && {
      key: 'case_number',
      field: 'case_number',
      type: ActiveFormFieldType.input,
      ionFieldGroup: {
        key: 'court_data',
        label: t('filterGroupLabels.court_data'),
        groupOrder: 4,
      },
      options: {
        placeholder: t('filters.case_number'),
      },
    },
    module.value === ProductionType.executive && {
      key: 'serial_number',
      field: 'serial_number',
      type: ActiveFormFieldType.input,
      ionFieldGroup: {
        key: 'court_data',
        label: t('filterGroupLabels.court_data'),
        groupOrder: 4,
        order: 4,
      },
      options: {
        placeholder: t('filters.serial_number'),
      },
    },
    {
      key: 'status_updated_at_after;status_updated_at_before',
      field: 'status_updated_at_after;status_updated_at_before',
      type: ActiveFormFieldType.dateRange,
      width: 2,
      options: {
        placeholder: t('filters.status_updated_at_before;status_updated_at_after'),
      },
      ionFieldGroup: {
        key: 'debtor_status',
        label: t('filterGroupLabels.debtor_status'),
        groupOrder: 3,
        order: 3,
      },
    },
    {
      key: 'accruals_from;accruals_to',
      field: 'accruals_from;accruals_to',
      type: ActiveFormFieldType.dateRange,
      width: 2,
      options: {
        placeholder: t('filters.accruals_from;accruals_to'),
      },
      ionFieldGroup: {
        key: 'debtor_status',
        label: t('filterGroupLabels.debtor_status'),
        groupOrder: 3,
        order: 3,
      },
    },
    !isIonicApp && {
      key: 'status_filter',
      field: 'status_filter',
      type: ActiveFormFieldType.cascader,
      defaultValue: [],
      width: 2,
      ionFieldGroup: {
        key: 'debtor_status',
        label: t('filterGroupLabels.debtor_status'),
        groupOrder: 3,
        order: 3,
      },
      options: {
        placeholder: t('filters.status_name'),
        isMultiple: true,
        checkStrictly: true,
        isFilterable: true,
        allowEmpty: true,
        collapseTags: true,
        collapseTagsTooltip: true,
        options: statusOptions.value,
      },
    },
    {
      key: 'document_number',
      field: 'document_number',
      type: ActiveFormFieldType.input,
      width: 2,
      ionFieldGroup: {
        key: 'document_number',
        label: 'Номер обращения',
        groupOrder: 4,
        order: 4,
      },
      options: {
        placeholder: 'Номер обращения',
      },
    },
    module.value === ProductionType.judicial && {
      key: 'magistrate_court_id',
      field: 'magistrate_court_id',
      type: ActiveFormFieldType.searchSelect,
      // async onQuery({ query }) {
      //   magistrateCourtsFiltersModel.value.name = query;
      // },
      ionFieldGroup: {
        key: 'court_data',
        label: t('filterGroupLabels.court_data'),
        groupOrder: 4,
        order: 4,
      },
      options: {
        state: 'drop-up',
        placeholder: t('filters.magistrate_court_id'),
        allowEmpty: true,
        valueField: 'id',
        displayField: 'name',
        prefetch: true,
        searchQuery: awaitMagistrateCourts.value,
      },
    },
    module.value === ProductionType.judicial && {
      key: 'regional_court_id',
      field: 'regional_court_id',
      type: ActiveFormFieldType.searchSelect,
      ionFieldGroup: {
        key: 'court_data',
        label: t('filterGroupLabels.court_data'),
        groupOrder: 4,
        order: 4,
      },
      options: {
        state: 'drop-up',
        placeholder: t('filters.regional_court_id'),
        allowEmpty: true,
        valueField: 'id',
        displayField: 'name',
        prefetch: true,
        searchQuery: awaitRegionalCourts.value,
      },
    },
    {
      key: 'assigned_users',
      field: 'assigned_users',
      type: ActiveFormFieldType.select,
      width: 2,
      ionFieldGroup: {
        key: 'assigned_users',
        groupOrder: 1,
        label: t('filterGroupLabels.extra_fields'),
      },
      options: {
        state: ['primary', 'drop-up'],
        placeholder: t('filters.employee_attached'),
        options: employees.value,
        allowEmpty: true,
        multipleAnyway: true,
      },
    },
    module.value === ProductionType.executive && {
      key: 'has_writ_of_execution',
      field: 'has_writ_of_execution',
      type: ActiveFormFieldType.select,
      width: 2,
      ionFieldGroup: {
        key: 'court_data',
        label: t('filterGroupLabels.court_data'),
        groupOrder: 4,
        order: 4,
      },
      options: {
        placeholder: t('filters.has_writ_of_execution'),
        options: [
          {
            value: true,
            label: rootT('other.yes'),
          },
          {
            value: false,
            label: rootT('other.no'),
          },
        ],
      },
    },
    {
      key: 'calculation_type_or',
      field: 'calculation_type_or',
      type: ActiveFormFieldType.select,
      width: 2,
      options: {
        state: 'drop-up',
        placeholder: 'Тип объекта',
        options: [
          {
            value: 'direct_calculations',
            label: 'МКД',
          },
          {
            value: 'private_house',
            label: 'ЧЖД',
          },
          {
            value: 'mfc',
            label: 'МФЦ',
          },
          {
            value: 'non_residential_premises',
            label: 'Нежилое помещение',
          },
          {
            value: 'independent_apartment',
            label: 'Отдельная квартира',
          },
          {
            value: 'pantry',
            label: 'Кладовая',
          },
          {
            value: 'car_place',
            label: 'Машиноместо',
          },
        ],
      },
    },
    companySettings.value?.show_administrative_district_filter && {
      key: 'okrug_short',
      field: 'okrug_short',
      type: ActiveFormFieldType.select,
      width: 2,
      options: {
        state: 'drop-up',
        placeholder: 'Округ',
        options: districtOptions,
        allowEmpty: true,
      },
    },
    module.value === ProductionType.executive && {
      key: 'bailiff_id',
      field: 'bailiff_id',
      type: ActiveFormFieldType.select,
      width: 2,
      async onQuery({ query }) {
        bailiffsFiltersModel.value.name = query;
      },
      ionFieldGroup: {
        key: 'court_data',
        label: t('filterGroupLabels.court_data'),
        groupOrder: 4,
        order: 4,
      },
      options: {
        state: 'drop-up',
        placeholder: t('filters.bailiff_id'),
        isSearchable: true,
        valueField: 'id',
        displayField: 'name',
        options: bailiffs.value,
      },
    },
    {
      key: 'register_compilation_date',
      field: 'register_compilation_date',
      type: ActiveFormFieldType.date,
      width: 2,
      ionFieldGroup: {
        key: 'register_compilation_date',
        groupOrder: 1,
        label: t('filterGroupLabels.register_compilation_date'),
      },
      options: {
        placeholder: t('filters.register_compilation_date'),
      },
    },
    {
      key: 'send_to_pochta_date',
      field: 'send_to_pochta_date',
      type: ActiveFormFieldType.date,
      width: 2,
      ionFieldGroup: {
        key: 'send_to_pochta_date',
        groupOrder: 1,
        label: t('filterGroupLabels.send_to_pochta_date'),
      },
      options: {
        placeholder: t('filters.send_to_pochta_date'),
      },
    },
    {
      key: 'oc',
      field: 'oc',
      type: ActiveFormFieldType.input,
      width: 2,
      options: {
        placeholder: 'ОС',
      },
    },
    {
      key: 'save_filters_as_default',
      field: 'save_filters_as_default',
      type: ActiveFormFieldType.checkbox,
      options: {
        label: t('filters.save_filters_as_default'),
      },
      stickBottom: true,
      ionFieldGroup: {
        key: 'extra_fields',
        label: t('filterGroupLabels.extra_fields'),
        groupOrder: 999,
        order: 5,
      },
    },
  ] as Array<ActiveFormField<DebtorQuery> | boolean>)
    .filter(Boolean) as Array<ActiveFormField<DebtorQuery>>)
    .map((field) => ({
      ...field,
      options: {
        ...field.options,
        id: `debtor_filters_${field.type}_${field.key}`,
      },
    })) as Array<ActiveFormField<DebtorQuery>>));

}
