import { useActiveTable } from '@/components/activeTable/useActiveTable';
import { Court, useCourts } from '@/hooks/useCourts';
import { computed, Ref, ref } from 'vue';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { Bailiff } from '@/hooks/useDebtors';
import { ProductionType } from '@/hooks/useConstructor';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';

export function useFilterCourts(
  module: Ref<ProductionType>,
) {
  const { company } = useDefaultCompany();
  const {
    fetchMagistrateCourts,
    fetchRegionalCourts,
    fetchBailiffs,
  } = useCourts();

  const {
    awaitRecords: awaitRegionalCourts,
  } = useActiveTable<Court, Court, 'id'>({
    keyField: 'id',
    name: 'regionalCourts',
    async fetch({ params, signal }) {
      const { response } = await fetchRegionalCourts({ ...params, signal });
      return response;
    },
    defaultLimit: ref(1000),
    filters: computed<Array<ActiveFormField<Court>>>(() => ([
      {
        key: 'name',
        field: 'name',
        type: ActiveFormFieldType.input,
      },
      {
        key: 'company_id',
        field: 'company_id',
        type: ActiveFormFieldType.input,
        defaultValue: company.value?.id,
      },
      {
        key: 'production_type',
        field: 'production_type',
        type: ActiveFormFieldType.input,
        defaultValue: module.value,
      },
    ])),
  });

  const {
    awaitRecords: awaitMagistrateCourts,
  } = useActiveTable<Court, Court, 'id'>({
    keyField: 'id',
    name: 'magistrateCourts',
    async fetch({ params, signal }) {
      const { response } = await fetchMagistrateCourts({ ...params, signal });
      return response;
    },
    defaultLimit: ref(10000),
    filters: computed<Array<ActiveFormField<Court>>>(() => ([
      {
        key: 'name',
        field: 'name',
        type: ActiveFormFieldType.input,
      },
      {
        key: 'company_id',
        field: 'company_id',
        type: ActiveFormFieldType.input,
        defaultValue: company.value?.id,
      },
      {
        key: 'production_type',
        field: 'production_type',
        type: ActiveFormFieldType.input,
        defaultValue: module.value,
      },
    ])),
  });

  const {
    records: bailiffs,
    filtersModel: bailiffsFiltersModel,
  } = useActiveTable<Bailiff, Bailiff, 'pk'>({
    keyField: 'pk',
    name: 'bailiffs',
    async fetch({ params, signal }) {
      const { response } = await fetchBailiffs({ ...params, signal });
      return response;
    },
    defaultLimit: ref(1000),
    filters: computed<Array<ActiveFormField<Court>>>(() => ([
      {
        key: 'name',
        field: 'name',
        type: ActiveFormFieldType.input,
      },
      {
        key: 'company_id',
        field: 'company_id',
        type: ActiveFormFieldType.input,
        defaultValue: company.value?.id,
      },
    ])),
  });

  return {
    awaitRegionalCourts,
    awaitMagistrateCourts,
    bailiffs,
    bailiffsFiltersModel,
  };
}
