import { Order } from '@/store/modules/api';
import { ActiveFormModel } from '@/hooks/useActiveForm';
import {
  onMounted, ref, Ref, UnwrapRef,
} from 'vue';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { RouterKey } from '@core/symbols';

export type QueryTableParams<T> = {
  page: Ref<number | null>;
  sort: Ref<Array<Order<T>> | null>;
  filters: Ref<Record<keyof T, any>| null>;
}

// В угоду производительности эта штука не делает полноценное двустороннее связывание
// фильтров с моделью, только подставляет инициализирующие значения и обновляет query
// если что-то поменялось в моделях
export const useInitQueryTableParams = <T>() => {
  const router = useProtectedInject(RouterKey);

  const getRoutePage = () => {
    const initPage = router.currentRoute.value?.query?.page;
    return initPage ? +initPage : null;
  };

  const getRouteFilters = () => {
    const routeFilters = router.currentRoute.value?.query?.filters;
    if (routeFilters) {
      try {
        const parsedRouteFilters = JSON.parse(routeFilters as string);
        if (parsedRouteFilters) {
          return parsedRouteFilters as ActiveFormModel<T>;
        }
      } catch (e) {
        console.log(e);
        return {} as ActiveFormModel<T>;
      }
    }
    return {} as ActiveFormModel<T>;
  };

  const getRouteSort = () => {
    const routeFilters = router.currentRoute.value?.query?.sort;
    if (routeFilters) {
      try {
        const parsedRouteFilters = JSON.parse(routeFilters as string);
        if (parsedRouteFilters) {
          return parsedRouteFilters as Array<Order<T>>;
        }
      } catch (e) {
        console.log(e);
        return null;
      }
    }
    return null;
  };

  const initPage = ref<UnwrapRef<QueryTableParams<T>['page']>|null>();
  const initFilters = ref<UnwrapRef<QueryTableParams<T>['filters']>|null>();
  const initSort = ref<UnwrapRef<QueryTableParams<T>['sort']>|null>();
  initPage.value = getRoutePage();
  initFilters.value = getRouteFilters();
  initSort.value = getRouteSort();
  console.log('useInitQueryTableParams', initFilters.value);

  return {
    page: initPage,
    filters: initFilters,
    sort: initSort,
  } as QueryTableParams<T>;
};
