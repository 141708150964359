import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import {
  onBeforeUnmount, ref, Ref, UnwrapRef,
} from 'vue';
import { RouterKey } from '@core/symbols';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { ProductionType } from '@/hooks/useConstructor';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { Debtor, useDebtors } from '@/hooks/useDebtors';
import { DebtorQuery } from '@/pages/debtors/_module/useDebtorsActions';
import { ActiveFormModel } from '@/hooks/useActiveForm';
import { Order } from '@/store/modules/api';
import debounce from 'lodash/debounce';
import {
  DebtorMeta,
  DebtorNeighboursMeta,
} from '@/components/dialog/dialogs/debtor/useDebtorDialog';
import { debtorIsOrganization } from '@/components/dialog/dialogs/debtor/tabs/common/tabs/main/useDebtorModel/utils';
import { mapDebtorsQueryModelToRequestFilters } from '@/pages/debtors/_module/mapDebtorsQueryModelToRequestFilters';

export const getDebtorModalTitle = (debtor: Debtor) => {
  if (debtorIsOrganization(debtor)) {
    return debtor.debtor_main_profile?.organization.name;
  }
  return debtor.debtor_main_profile?.full_name;
};
export const useDebtorNavigation = (
  module: Ref<ProductionType>,
  records: Ref<UnwrapRef<Debtor[]>>,
  page: Ref<number>,
  limit: Ref<number>,
  filtersModel: Ref<ActiveFormModel<DebtorQuery>>,
  sort: Ref<Array<Order<DebtorQuery>>>,
  total: Ref<number>,
) => {
  const router = useProtectedInject(RouterKey);
  const {
    showDialog,
    closeDialogByComponent,
  } = useDialog();

  const { fetchDebtors } = useDebtors();

  const {
    subscribeToSignal,
    dispatchSignal,
  } = useSignal();

  onBeforeUnmount(() => {
    return closeDialogByComponent(IDialogComponent.debtor);
  });

  const debtorNeighboursData = ref<DebtorNeighboursMeta|null>(null);

  const showDebtorDialog = async (
    meta: DebtorMeta,
  ) => {
    console.log('showDebtorDialog', meta);
    let currentConfig: any;

    try {
      currentConfig = JSON.parse(
        router.currentRoute.value.hash.substring(1),
      );
      delete currentConfig.payload;
      delete currentConfig.component;
    } catch (e) {
      // no current config
    }

    await showDialog({
      id: 'debtor',
      component: IDialogComponent.debtor,
      payload: {
        id: meta.id,
        productionType: module.value,
        isOrganization: meta.isOrganization,
        name: meta.name,
        personalAccount: meta.personalAccount,
        tableOffset: meta.tableOffset,
      },
      params: {
        ...currentConfig,
      },
    });
  };

  const onRowClick = async (
    {
      record: { debtor: { pk, personal_account }, debtor_main_profile },
    }: {
      record: Debtor;
    },
  ) => {
    const recordIndex = records.value.findIndex((d) => d.debtor.pk === pk);
    if (recordIndex === -1) {
      console.error('record index not found');
    }
    const payloadTableOffset = recordIndex + ((page.value - 1) * limit.value);
    await showDebtorDialog({
      id: pk,
      isOrganization: !!debtor_main_profile.organization,
      name: getDebtorModalTitle({ debtor_main_profile } as Debtor),
      personalAccount: personal_account,
      tableOffset: payloadTableOffset,
    });
  };

  const getCachedDebtorNeighbours = (debtorId: number) => {
    if (!debtorNeighboursData.value) return { next: null, prev: null, total: null };
    const curIndex = debtorNeighboursData.value.neighbours.findIndex(
      ({ id }) => id === debtorId,
    );
    if (curIndex === -1) {
      return {
        next: null, prev: null, total: null,
      };
    }
    const neighboursLength = debtorNeighboursData.value.neighbours.length;
    let next; let
      prev;
    if (curIndex === neighboursLength - 1) {
      prev = debtorNeighboursData.value.neighbours[neighboursLength - 2];
      next = debtorNeighboursData.value.neighbours[0];
    } else if (curIndex === 0) {
      prev = debtorNeighboursData.value.neighbours[neighboursLength - 1];
      next = debtorNeighboursData.value.neighbours[1];
    } else {
      prev = debtorNeighboursData.value.neighbours[curIndex - 1];
      next = debtorNeighboursData.value.neighbours[curIndex + 1];
    }
    return {
      next,
      prev,
      total: debtorNeighboursData.value.total,
    };
  };

  const fetchDebtorNeighbours = async (
    e: { debtorId: number; tableOffset: number },
  ) => {
    const DEFAULT_LIMIT = 11;
    const offset = e.tableOffset - 5;

    const tableOverflow = offset + DEFAULT_LIMIT - total.value;
    if (tableOverflow > 0) {
      await fetchDebtors({
        filters: mapDebtorsQueryModelToRequestFilters(filtersModel.value),
        ordering: sort.value,
        limit: tableOverflow,
        offset: 0,
        page: null as unknown as number,
      });
    }

    const promises = [];

    if (offset < 0) {
      promises.push(fetchDebtors({
        filters: mapDebtorsQueryModelToRequestFilters(filtersModel.value),
        ordering: sort.value,
        limit: DEFAULT_LIMIT,
        offset,
        page: null as unknown as number,
      }));
      promises.push(fetchDebtors({
        filters: mapDebtorsQueryModelToRequestFilters(filtersModel.value),
        ordering: sort.value,
        limit: Math.abs(offset),
        offset: total.value + offset,
        page: null as unknown as number,
      }));
    } else if (tableOverflow > 0) {
      promises.push(fetchDebtors({
        filters: mapDebtorsQueryModelToRequestFilters(filtersModel.value),
        ordering: sort.value,
        limit: tableOverflow,
        offset: 0,
        page: null as unknown as number,
      }));
      promises.push(fetchDebtors({
        filters: mapDebtorsQueryModelToRequestFilters(filtersModel.value),
        ordering: sort.value,
        limit: DEFAULT_LIMIT,
        offset,
        page: null as unknown as number,
      }));
    } else {
      promises.push(fetchDebtors({
        filters: mapDebtorsQueryModelToRequestFilters(filtersModel.value),
        ordering: sort.value,
        limit: DEFAULT_LIMIT,
        offset,
        page: null as unknown as number,
      }));
      // stub
      promises.push({
        status: true,
        response: {
          results: [],
        },
      });
    }

    const [
      neighboursResponse,
      neighboursFromTheEndResponse,
    ] = await Promise.all(promises);

    if (!neighboursResponse.status || !neighboursFromTheEndResponse.status) {
      return;
    }

    const currentDebtorIndex = neighboursResponse.response.results.findIndex(
      (d) => d.debtor.pk === e.debtorId,
    );

    const neighbours1 = [
      ...neighboursFromTheEndResponse.response.results.map((debtor, i, arr) => ({
        id: debtor.debtor.pk,
        name: getDebtorModalTitle(debtor),
        personalAccount: debtor.debtor.personal_account,
        // isOrganization: !!debtor.debtor_main_profile.organization,
        tableOffset: total.value - arr.length + i,
      })),
      ...neighboursResponse.response.results.map((debtor, i) => ({
        id: debtor.debtor.pk,
        name: getDebtorModalTitle(debtor),
        personalAccount: debtor.debtor.personal_account,
        // isOrganization: !!debtor.debtor_main_profile.organization,
        tableOffset: tableOverflow > 0 ? i : e.tableOffset + (i - currentDebtorIndex),
      })),
    ];

    console.log('neighbours', neighbours1);

    debtorNeighboursData.value = {
      current: e.debtorId,
      total: total.value,
      neighbours: neighbours1,
    };

    console.log('debtorNeighboursData.value', debtorNeighboursData.value);

    await dispatchSignal(
      SignalType.debtorNeighboursFound, {
        currentId: e.debtorId,
        ...getCachedDebtorNeighbours(e.debtorId),
      },
    );
  };

  const fetchDebtorNeighboursDebounced = debounce(
    fetchDebtorNeighbours,
    300,
    {
      leading: true,
      trailing: true,
    },
  );

  onBeforeUnmount(
    subscribeToSignal(SignalType.findDebtorNeighbours, async (e) => {
      if (debtorNeighboursData.value) {
        await dispatchSignal(
          SignalType.debtorNeighboursFound, {
            currentId: e.debtorId,
            ...getCachedDebtorNeighbours(e.debtorId),
          },
        );
      }
      fetchDebtorNeighboursDebounced(e);
    }),
  );

  onBeforeUnmount(
    subscribeToSignal(SignalType.navigateDebtor, (e) => {
      return showDebtorDialog(e);
    }),
  );

  return {
    onRowClick,
  };
};
